import './Login.css';
import { useState } from 'react';
import { useNavigate } from "react-router-dom"

const Login = () => {

    let nav = useNavigate();

    const [uname, setUname] = useState('');
    const [pwd, setPwd] = useState('');
    const [msg, setMsg] = useState('');

    const axios = require('axios');

    const handleClick = (e) => {

        e.preventDefault();

        const body = {
            uname: uname,
            pwd: pwd
        }

        axios.post('https://footsprint.uc.r.appspot.com/getAuthInfo', body)
            .then(response => {
                setMsg("Login Success");
                
                nav("/carbon-calculator-page", {state : {
                    authorized : true,
                    k : response.data
                }})
            })
            .catch(error => {
                console.log(error);
                setMsg("Login Failed");
            });


    }


    return (
        <div className="main">
            <p className="sign" align="center">Sign in</p>
            <form className="form1" onSubmit={handleClick}>
                <input className="un " type="text" align="center" placeholder="Username" value={uname} onChange={(e) => setUname(e.target.value)} />
                <input className="pass" type="password" align="center" placeholder="Password" value={pwd} onChange={(e) => setPwd(e.target.value)} />
                <button type="submit" onClick={handleClick} value="Sign in" className="submit">Sign in</button>
                <p className="msg">{msg}</p>
            </form>
        </div>
    );
}

export default Login;